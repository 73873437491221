import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const Footer = (props) => {
    return (
        <div className="footer textcenter">           
            

            <div className="copyright">©2022 Fairybaby420<br />
                <Link to="/terms">
				    Terms & Conditions
				</Link><br />
                <a href="https://etherscan.io/address/0x213E46C9273bE8595CfC7a266F0978782C2e6a24" target="_blank">
                    Contract on Etherscan
                </a>
            </div>
            

        </div>
    );
};

export default Footer;
