import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";


const Team = (props) => {
    return (
        <div className="App">
        {
            <div>
                
                    <div className="bigcontainer greencontainer">
                        <h1 style={{marginTop:0, marginBottom: "30px", color: "#000"}} >Team</h1>

                        <div className="teammember left p33pad">
                            <a href="https://twitter.com/fairybaby420" target="_blank"><img className="teampic" src="/team/fairybb.png" /></a><br />
                            <div className="subtitleteam">
                            <h3>Fairybaby420</h3>
                            <h4>Shitposter/Artist and gym bro</h4>
                            </div>
                        </div>

                        <div className="teammember left p33pad">
                            <img className="teampic" src="/team/drdegen.png" /><br />
                            <div className="subtitleteam">
                            <h3>Dr. Degen</h3>
                            <h4>Part-time doctor full-time degen</h4>
                            </div>
                        </div>

                        <div className="teammember left p33pad">                          
                            <a href="https://twitter.com/donbuidl0r" target="_blank"><img className="teampic" src="/team/don.png" /></a>
                                <div className="subtitleteam">
                                <h3>Donatell0</h3>
                                <h4>Gud Ketschup and Golemz lover</h4>
                            </div>
                        </div> 

                        <div className="teammember left p33pad">
                            <a href="https://twitter.com/TheChadFairy" target="_blank"><img className="teampic" src="/team/chadfairy.png" /></a><br />
                            <div className="subtitleteam">
                            <h3>Chad Fairy</h3>
                            <h4>Allroundooooor</h4>
                            </div>
                        </div>      

                        <div className="teammember left p33pad">
                            <a href="https://twitter.com/moonie_gmi" target="_blank"><img className="teampic" src="/team/moonie.png" /></a><br />
                            <div className="subtitleteam">
                            <h3>moonie</h3>
                            <h4>King of vibez</h4>
                            </div>
                        </div>                   

                        <div className="clearfix"></div>

                        
                    </div>

                <Footer />
            </div>
        }
        </div>
    );
};

export default Team;
