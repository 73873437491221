import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./indexv1.css";


import App from "./App";


import './fonts/stylesheet.css';

ReactDOM.render(
    <App></App>,
    document.getElementById("root"),
);

