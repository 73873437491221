import React, { useState }  from "react";
import "./index.css";
import "./indexv1.css";

import Header from "./Header";
import Terms from "./Terms";
import Story from "./Story";
import StoryWhitelist from "./StoryWhitelist";
import About from "./About";
import Journey from "./Journey";
import Team from "./Team";
import Shop from "./Shop";
import Chapter1 from "./Chapter1";
import reportWebVitals from "./reportWebVitals";

import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";

 import './fonts/stylesheet.css';

 

 const App = (props) => {

    const [user, setThisUser] = useState(null);

	return (
	<React.StrictMode>
		
		<BrowserRouter>
		<Header user={user} setThisUser={setThisUser}></Header>
			<Routes>
				<Route path="/" element={<Story user={user} setThisUser={setThisUser}/>} />
				<Route path="/Whitelistz" element={<StoryWhitelist user={user} setThisUser={setThisUser}/>} />
				<Route path="/terms" element={<Terms />} />
				<Route path="/about" element={<About />} />
				<Route path="/journey" element={<Journey />} />
				<Route path="/team" element={<Team />} />
				<Route path="/Shop" element={<Shop />} />
				<Route path="/Chapter1" element={<Chapter1 />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
	)
	

 }

 export default App;
