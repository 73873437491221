import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { Network, Alchemy } from 'alchemy-sdk';

import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { convertUtf8ToHex } from "@walletconnect/utils";

import Web3 from "web3";

import { useNavigate } from 'react-router-dom';

import {isWhiteListed } from "./utils/interact.js";
import { whiteListAddresses } from "./utils/whitelistaddresses.js";


const Header = (props) => {

    const provider = new WalletConnectProvider({
        rpc: {
            1: process.env.REACT_APP_ALCHEMY_KEY2,
            // other networks here
          },
      });

    const box = useRef(null);
    let c = 0;

    const [walletAddress, setWallet] = useState("");
    const [signature, setSignature] = useState("");

    const [loginError, setLoginError] = useState(false);
    const [jwtToken, setJwtToken] = useState("");
    const [connectedWith, setConnectedWith] = useState(null);
    const [user, setThisUser] = useState(null);

    const [wcProvider, setWcProvider] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    let web3 = undefined;

    /**
     * useEffect for resetting states and localStorage if no user is connected onLoad
     */
    useEffect(() => {
        if (!user) {
            const userFromLocalStorage = window?.localStorage?.getItem("user");
            let temp = JSON.parse(userFromLocalStorage) ?? null;
            props.setThisUser(temp);
            setIsLoading(false);

            if (temp) {
                setIsLoggedIn(true);
            }
        }
    }, []);


    const handleMetamaskSign = async (
        walletAddress
    ) => {
        try {
            web3 = new Web3(window.ethereum);

            const signature = await web3.eth.personal.sign(
                "Gmairy Fren " + walletAddress + "!.\nPlease sign ze message to log in and start your fairytale.",
                walletAddress,
                "",
            );

            return { walletAddress };
        } catch (err) {
            throw new Error(
                err, "You need to sign the message to be able to log in.",
            );
        }
    };
    
    /**
     * Show Walletconnect Sign Message
     *
     * @param web3
     * @param walletAddress
     * @param token
     */
    const handleWalletConnectSign = async ({
        connector,
        walletAddress,
        token,
    }) => {
        try {
            /* const signature = await web3!.eth.personal.sign(
                token,
                walletAddress,
                "",
            );*/
    
            let signature = await connector.signPersonalMessage([convertUtf8ToHex(token), walletAddress]);

            return signature;

/*
                .then((signature) => {
                    // Returns signature.
                    return { walletAddress, token, signature };
                });*/
            //let signedMessage = await web3!.eth.sign(token, walletAddress);
    
            //return { walletAddress, token, signature };
        } catch (err) {
            throw new Error(
                "You need to sign the message to be able to log in.",
            );
        }
    };
    


    /**
     * WalletConnect Handler
     *
     * @param afterLoginHandler
     */
    const connectWalletConnect = async () => {

            try {
                const connector = new WalletConnect({
                    bridge: "https://bridge.walletconnect.org", // Required
                    qrcodeModal: QRCodeModal,
                });

                let account = null;

                if (!connector.connected) {
                    connector?.createSession();
                }

                connector?.on("connect", async (error, payload) => {
                    if (error) throw error;
                    const { accounts, chainId } = payload.params[0];

                    account = accounts[0];  
                    
                    let msg = "Gmairy Fren " + walletAddress + "!.\nPlease sign ze message to log in and start your fairytale.";

                    const account = accounts[0];

                    let res = await handleWalletConnectSign({ connector, account, msg})                        

                    let jwtToken = res.token;

                    if (jwtToken) {
                        setUser({
                            walletAddress: account,
                            connectedWith: "walletconnect",
                            jwtToken: jwtToken
                        });
                        setWcProvider(window?.ethereum?.on(
                            "accountsChanged",
                            (accounts) => null,
                        ));
                    }


                });

                connector?.on("session_update", (error, payload) => {
                    if (error) throw error;
                    const { accounts, chainId } = payload.params[0];
                    account = accounts[0];
                });

                connector?.on("disconnect", (error, payload) => {
                    if (error) throw error;
                });
            } catch (e) {
                console.log("Connect Error: ", e);
                await disconnect();
            }
        
    };

    /**
     * MetaMask Connect Handler
     *
     * @param afterLoginHandler
     */
    const connectMetamask = async () => {
        if (!user?.walletAddress || !Web3) {
            try {
                if (typeof window?.ethereum !== "undefined") {
                    let web3MM = new Web3(window.ethereum);
                    const accounts = await window?.ethereum.request({
                        method: "eth_requestAccounts",
                    });
                    const account = accounts[0];

                   let res = await handleMetamaskSign(account);
                       
                    let token = res;

                    if (token) {
                        
                        setUser({
                            walletAddress: account,
                            connectedWith: "metamask",
                            token: token
                        });

                        window?.ethereum?.on(
                            "accountsChanged",
                            (accounts) => {      
                                console.log("Account changed");
                                setUser({
                                    walletAddress: accounts[0],
                                    connectedWith: "metamask",
                                });
                            },
                        );
                    }

                } else {
                    alert(
                        "Metamask is not installed!\nInstall MetaMask or use WalletConnect",
                    );
                }
            } catch (e) {
                console.error("Connect Error: ", e);
                await disconnect();
            }
        }
    };



    /**
     * Set User on LocalStorage and State
     *
     * @param pUser
     */
    const setUser = async (pUser) => {

        if(pUser != null)
        {
            pUser.isWhitelisted = false;

            /*whiteListAddresses.forEach((address, index) => {
                if(address.toLowerCase() == pUser.walletAddress.toLowerCase())
                {
                    pUser.isWhitelisted = true;
                }
              });*/
            
            pUser.isWhitelisted = await isWhiteListed(whiteListAddresses,pUser.walletAddress);
        }

        props.setThisUser(pUser);
        window?.localStorage?.setItem("user", JSON.stringify(pUser));
        if (pUser) {
            setIsLoggedIn(true);
        }
    };

    /** Disconnect and reset states and localStorages **/
    const disconnect = async () => {
        wcProvider?.on("disconnect", (error, payload) => {
            if (error) throw error;
        });
        window?.localStorage?.removeItem("user");
        window?.localStorage?.removeItem("walletconnect");
        setUser(null);
        setWcProvider(null);
        setIsLoggedIn(false);

    };


    //FairyFren
	const [frentop, setFrentop] = useState(false);
  
	const [fren, setFren] = useState(false);

    const location = useLocation();
    const header = useRef();

    let frenRef = useRef();

    useEffect(() => {

        
		setFren(Math.floor(Math.random() * 14) + 1);

        let ftop = Math.floor(Math.random() * 80) + 10;

		if(ftop > 70)
		{
			ftop = 70;
		}

        if(frentop < 30)
		{
			ftop = 30;
		}

        setFrentop(ftop);

		if(window.location.pathname === "/" || window.location.pathname === "/Whitelistz")
        {
            header.current.classList.add("absoluteheader");
        }
        else
        {
            header.current.classList.remove("absoluteheader");
        }


		return () => {}
	}, [location.pathname]);

    const drawbackFren = () => {

		frenRef.current.classList.add("hide");
	}

	const comebackFren = () => {

		frenRef.current.classList.remove("hide");
	}


	const [audio] = useState(new Audio("/fairysound.mp3"));
	const [playing,setPlaying] = useState(false);

	let sound = useRef();
        
    const playPause = () => {
        try{
                
            if(!playing)
            {
                audio.pause();
                setPlaying(true);
                audio.play();							
            }
            else
            {
                setPlaying(false);
                audio.pause();	
            }

        } catch(error)
        {
            console.log(error);
        }
    }

    

    return (
        <div className="header" ref={header}>

        <div className="legacybanner">
            Fairyfrenz have moved on July 13th 2023!<br /><a href="https://fairyfrenz.xyz">Click here to switch to the new site!</a>
        </div>
            
            <div id="title" className="title">
                <Link to ="/">
                    <img src="title.png" className="logotitle"/>
                </Link>
            </div>            
            
            <div className="navi">
                <Link to="/about" className="button">
                        <img src="navi_about.png" />
                </Link>
                <Link to="/journey" className="button">
                        <img src="navi_journey.png" />
                </Link>
                <Link to="/team" className="button">
                        <img src="navi_team.png" />
                </Link>  
                <Link to="/shop" className="button">                        
                        <img src="navi_webshop.png" />
                </Link>   
                <div className="clearfix"></div>
            </div>


            { /*<div
                onClick={() =>
                    connectWalletConnect()
                }

                className="loginbutton"
            >
                 Connect via Walletconnect
            </div> */}

            {
                props.user?.walletAddress ? (
                    <div className="loginbutton">

                        <img src="disconnect.png" onClick={() =>
                                disconnect()
                            }    />   

                        <div className="walletaddress">
                            {props.user?.walletAddress}
                        </div>

                    </div>

                ) : (
                    <div className="loginbutton">

                            <img src="connectwallet.png" onClick={() =>
                                connectMetamask()
                            }    />


                        {/*<br /><br /><br />

                        <div
                        onClick={() =>
                            connectWalletConnect()
                        }

                        >
                        Connect via WalletConnect
                    </div> */ }

                    </div>

                )
            }
                     

            

            <img src="sound_small.png" className="sound" id="sound" ref={sound} onClick={ () => { playPause(); }} />
            <a target="_blank" href="https://twitter.com/FairyFrenz" className="twitter" ><img src="twitter2.png"  /></a>
            <a target="_blank" href="https://discord.gg/dYpKAHUbcE" className="discord" ><img src="discord.png"  /></a>

            <div  className="fairyfrenpopup" style={{"top":frentop+"%"}}onMouseEnter={() => drawbackFren()} onMouseLeave={() => comebackFren()} ref={frenRef}>

            <img src={"ffz/"+fren+".png"} className=""></img>

            </div>

            {/*
                walletAddress == "" ? (
                <div className="connectwallet">
                    <a  onClick={connectWalletPressed}>
                       Connect wallet
                    </a>
                </div>

                ) : (
                    <div className="connectwallet">
                        <a onClick={disconnectWalletPressed}>
                            {walletAddress.substring(0, 6)}...{walletAddress.substring(38, 42)}
                        </a>
                    </div>
                    
                )
                */}
            
                    
            {loginError && (
                <div className="loginerror">
                    Could not connect wallet. <br />
                    <span className="smallfont">
                        Do you have{" "}
                        <a href="https://metamask.io" target="_blank">
                            Metamask
                        </a>{" "}
                        installed? <br />
                        Right now only Metamask wallet is supported.
                    </span>
                </div>
            )}

        </div>
    );
};

export default Header;
