import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";


const Journey = (props) => {

    const map = useRef();


    return (
        <div className="App">
        {
            <div>
                
                <div className="bigcontainer greencontainer">
                        
                        <div className="left p45 wrapframe">
                            <img src="/map_small.png" className="map"  />

                        </div>

                        <div className="right p50">

                            <h1 style={{marginTop:0, marginBottom: "30px", color: "#000"}} >Journey</h1>
                            
                            FairyFrenz was founded a year ago when Fairybaby420 showed Dr. Degen a mindmap...

                            <p>
                            Deeply impressed and heavily inspired, Dr. Degen began analyzing and interpreting what Fairybaby420 couldn't express in words. The result? Web3's first original crypto fairy tale. A few more frenz then joined the team and together they came up with the following 3 Phases:
                            <br /><br /><br />
                            <h2>Phase 1 - THE MORAL LESSON:</h2>The fairy tale is accessible to everyone, and minting is live
                            <br /><br />
                            - Parallel to the increase in the number of Badazz FairyFrenz PFPs buyers, the fairy tale begins to get limited for non-buyers.<br />
                            - FairyFrenz PFP hodlers own IP rights to the story as well as to the artwork of the NFT's.<br />
                            - Hodlers have access to all exclusive Discord channels.<br />
                            <br /><br /><br />
                            <h2>Phase 2 - THE MAGIC:</h2> Badazz FairyFrenz PFPs sell out
                            <br /><br />
                            - FairyFrenz PFP hodlers continue to have access to the crypto fairy tale. Everyone else doesn't.<br />
                            - Webshop with exclusive FairyFrenz merchandise featuring limited edition hoodies, tees, zines and more gets unlocked.<br />
                            - Exclusive airdrops for FairyFrenz hodlers.
                            <br /><br /><br />
                            <h2>Phase 3 - THE HAPPILY EVER AFTER:</h2> Development of the next releases
                            <br /><br />
                            - Through a voting system, FairyFrenz PFP hodlers can influence the content of part two of the crypto fairy tale.<br />
                            - FairyFrenz PFP hodlers can pitch their own projects.<br />
                            - Drafting of the next drop kicks off a new cycle, starting all over again at phase 1 THE MORAL LESSON. Talk about WAGMI self-sufficiency!


                            </p>

                   
                        </div>

                        <div className="clearfix" />

                    </div>

                <Footer />
            </div>
        }
        </div>
    );
};

export default Journey;
