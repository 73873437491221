import axios from "axios";
import { Network, Alchemy } from 'alchemy-sdk';

import keccak256 from "keccak256";
import { MerkleTree } from "merkletreejs";

require("dotenv").config();

const contractAddress = "0x213E46C9273bE8595CfC7a266F0978782C2e6a24";

const DEV = process.env.REACT_APP_DEV;

let settings = {};

let contractABI;

if(process.env.REACT_APP_DEV==="dev")
{
    settings = {
        apiurl: process.env.REACT_APP_ALCHEMY_KEY_DEV,
        network: Network.ETH_GOERLI,
    }

    contractABI = require("../contract/contract-abi.json"); 
}
else //LIVEEEEE
{
    settings = {
        apiurl: process.env.REACT_APP_ALCHEMY_KEY_LIVE,
    };

    contractABI = require("../contract/contract-abi.json"); 
}

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(settings.apiurl);

//For getNFTS
//const apiKey = settings.apiKey;
//let baseURL = `https://eth-mainnet.g.alchemy.com/v2/${apiKey}/getNFTs/`;


export const isWhiteListed = async (whitelistAddresses,address) => {

    let proof = getMerkleProof(whitelistAddresses,address);

    if (address !== "") {
        window.contract = await new web3.eth.Contract(
            contractABI,
            contractAddress,
        );

        try {
            return await window.contract.methods
                .isWhiteListed(address,proof)
                .call(function (error, result) {
                    if (!error) return result;
                    else return -1;
                });
        } catch (error) {
            console.log("Error with whitelist", error);
            return 0;
        }
    }
}

export const totalSupply = async (address) => {

    if (address !== "") {
        window.contract = await new web3.eth.Contract(
            contractABI,
            contractAddress,
        );

        try {
            return await window.contract.methods
                .totalSupply()
                .call(function (error, result) {
                    if (!error) return result;
                    else return -1;
                });
        } catch (error) {
            console.log("Error with totalsupply", error);
            return -1;
        }
    }
}

export const mintPhase = async () => {

    window.contract = await new web3.eth.Contract(
        contractABI,
        contractAddress,
    );

    try {
        return await window.contract.methods
            .mintPhase()
            .call(function (error, result) {
                if (!error) return result;
                else return -1;
            });
    } catch (error) {
        console.log("Error with totalsupply", error);
        return -1;
    }
    
}


export const tryToMint = async (whitelistAddresses, walletAddress, amount,  mintwrapper, mintingwrapper, setMintinginProgress) => {

    let finalprice = 0.0666 * amount;

    const ethPrice = String(web3.utils.toWei((finalprice).toString(), "ether"));
    let value = web3.utils.toHex(ethPrice);

    if (web3.utils.isAddress(walletAddress) && amount <= 333) {

        let contract = new web3.eth.Contract(contractABI, contractAddress);      

        const totalValue = value;
        contract.methods.gm(amount).send({ from: walletAddress, value: totalValue })
        .on('transactionHash', function(hash){
            waitForMintFinished(hash, amount, mintwrapper, mintingwrapper, setMintinginProgress);
        }).on('receipt', function(receipt){
            mintFinished(receipt.transactionHash, amount, mintwrapper, mintingwrapper, setMintinginProgress);
        }).on('error', function(error){
            console.log(error);
        });

       /*let transactionParameters = {
            from: walletAddress, 
            value: value,
            data: window.contract.methods
                .gm(amount)
                .encodeABI(), //make call to NFT smart contract
        };       
        
        console.log(transactionParameters);

        //sign the transaction via Metamask
        try {

            const txHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [transactionParameters],
            });

            waitForMintFinished(txHash, amount);

            return {
                success: true,
                status:
                    "<br /><div className='loader center'><span /><span /></div><h3>Uniting your sph3res:</h3><h2><a href='https://etherscan.io/tx/" +
                    txHash +
                    "' target='_blank'>Track transaction on Etherscan</a></h2>",
                hash: txHash,
            };
        } catch (error) {

            
            return {
                success: false,
                status: "Error: " + error.message,
                hash: "",
            };
        }*/

    } else {
        return {
            success: false,
            status: "Error: Invalid address or amount.",
            hash: "",
        };
    }

}

export const tryToMintWL = async (whitelistAddresses, walletAddress, amount,  mintwrapper, mintingwrapper, setMintinginProgress) => {

    const BN = web3.utils.BN;

    const amountInWei = new BN(web3.utils.toWei(amount.toString(), "ether"));
    
    // Convert decimal to fraction (0.0333 = 333/10000)
    const numerator = new BN(333);
    const denominator = new BN(10000);
    
    // Perform integer multiplication and division
    const ethPrice = amountInWei.mul(numerator).add(denominator.subn(1)).div(denominator);

    const value = web3.utils.toHex(ethPrice);

    let proof = getMerkleProof(whitelistAddresses,walletAddress);


    if (web3.utils.isAddress(walletAddress) && amount <= 333) {

        let contract = new web3.eth.Contract(contractABI, contractAddress);     

        const totalValue = value;

        contract.methods.gmairy(amount, proof).send({ from: walletAddress, value: totalValue })
        .on('transactionHash', function(hash){
            waitForMintFinished(hash, amount, mintwrapper, mintingwrapper, setMintinginProgress);
        }).on('receipt', function(receipt){
            mintFinished(receipt.transactionHash, amount, mintwrapper, mintingwrapper, setMintinginProgress);
        }).on('error', function(error){
            console.log(error);
        });

    } else {
        return {
            success: false,
            status: "Error: Invalid address or amount.",
            hash: "",
        };
    }

}



export const waitForMintFinished = async (txHash, amount,  mintwrapper, mintingwrapper, setMintinginProgress) => {
    setMintinginProgress(true);

    if(mintwrapper != null)
    {

        mintwrapper.current.classList.add("hide");
        mintingwrapper.current.classList.remove("hide");
        mintingwrapper.current.innerHTML =  "<br /><div className='loader center'><span /><span /></div><h3>Minting your FairyFrenz</h3><h2><a style='color: #ff00ff;' href='https://etherscan.io/tx/" +
                                            txHash +
                        "                   ' target='_blank'>&gt; Track transaction on Etherscan &lt;</a></h2><img src='glitter.gif' alt='glitter' />";

    }
}

export const mintFinished = async (txHash, amount,  mintwrapper, mintingwrapper, setMintinginProgress) => {
    setMintinginProgress(false);

    if(mintwrapper != null)
    {
        mintwrapper.current.classList.add("hide");

        mintingwrapper.current.innerHTML =  "<br /></div><h3>Your FairyFrenz are here!!!</h3><h2><a style='color: #ff00ff;' href='https://etherscan.io/tx/" +
        txHash +
    "                   ' target='_blank'>&gt; Open Etherscan &lt;</a></h2><img src='fairyminted.png' style='max-width:100%;' alt='Fairy minted' />";
    }

}




//WHITELIST

/**
 * https://medium.com/@ItsCuzzo/using-merkle-trees-for-nft-whitelists-523b58ada3f9
 */

/**
 * This is to ensure the addresses is valid and injects the checksum
 */
export const getAndConvertAddresses = (addresses) => {
  if (!addresses?.length) return [];

  const convertedAddress = addresses.map((addr) => {
    return web3.utils.toChecksumAddress(addr);
  });

  return convertedAddress;
};

const generateMerkle = (addresses) => {
  if (!addresses?.length) return null;

  // This is to ensure the addresses is valid and injects the checksum
  const convertedAddresses = getAndConvertAddresses(addresses);

  /**
   * Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
   * using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
   * The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
   */
  const leafNodes = convertedAddresses.map((addr) => keccak256(addr));

  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
  const merkleRootHash = merkleTree.getRoot().toString("hex");
  const merkleTreeString = merkleTree.toString();

  return {
    merkleTree,
    merkleRootHash: `0x${merkleRootHash}`,
    merkleTreeString,
  };
};

/**
 * A function that generates merkle proof hex string
 * @param whitelistAddresses | string []
 * @param walletAddress | string
 * @returns string[]
 */
const getMerkleProof = (
  whitelistAddresses,
  walletAddress
) => {
  const convertedAddresses = getAndConvertAddresses([walletAddress]);
  const merkle = generateMerkle(whitelistAddresses);
  const hashAddress = keccak256(convertedAddresses[0]);
  const proof = merkle?.merkleTree.getHexProof(hashAddress);
  return proof || [];
};

const isWhiteList = (
  whitelistAddresses,
  walletAddress
) => {
  const convertedAddresses = getAndConvertAddresses([walletAddress]);
  const merkle = generateMerkle(whitelistAddresses);
  const hashAddress = keccak256(convertedAddresses[0]);
  const proof = merkle?.merkleTree?.getHexProof(hashAddress) || [];
  const verify = merkle?.merkleTree.verify(
    proof,
    hashAddress,
    merkle?.merkleRootHash
  );

  return !!verify;
};

export { generateMerkle, getMerkleProof, isWhiteList };

