import {useEffect, useState, useRef} from "react";
import {useInView} from 'react-intersection-observer';
import { Link } from "react-router-dom";

import Header from "./Header";

const Chapter1 = (props) => {

	//const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const v0 = useRef();
    const setheight = useRef();
    const playbackConst = 1000;
    const [c,setC] = useState(0);

    const forceheight = 700;

    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
  
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    }
    

    useEffect(() => {      

        let setHeight = setheight.current;

        // select video element         
        let vid = v0.current;

        // dynamically set the page height according to video length
        vid.addEventListener('loadedmetadata', function() {
              let h = vid.duration * playbackConst;

            setHeight.style.height = forceheight + "px";
            //setHeight.style.height = vid.duration * playbackConst + "px";
        });

        window.addEventListener('scroll', onScroll);

	}, []);

    useEffect(() => {      


        let vid = v0.current;

        // Use requestAnimationFrame for smooth playback

            setC(c+1);

            if(c == 0 || c % 5 == 0)
            {
                let n = window.pageYOffset/playbackConst;

                vid.currentTime  = n;
    
            }

		return () => {

        }
	}, [scrollTop]);

   

	return (
		<div className="wrapvideochapter" >

            <section className="container">
                <div className="content">
                    <h1>Chapter 1</h1>
                    <p>Scroll to begin</p>
                </div>
            </section>

            <section className="containertextwrap" style={{marginTop:"60%"}}>
                <div className="containertext">
                    <div className="content">
                        <h1>A Very Brief Introduction.</h1>
                        <p>Long before the world became what it is today, far, far away, beyond all countries and seas, there, in the midst of the metaverse, where the sun neither fully rose nor set, but rather, as if buffering, remained in a state of constant sunset, stood a fog-enshrouded valley known as Rock Valley.
    Tucked away behind endless-seeming alphanumerical forests and countless vertical digital cliffs, this valley, a peaceful sort of place, was ruled over by a rock. What does that mean? It means a rock was in political power. What does that mean? I don't know. All I know is that the rock had a value that rises to inﬁnity during this story, and that it couldn't talk and that it couldn't move. While being 100% real rock, it could do absolutely nothing. 
    </p>
    <p>With an average of 29,291.4 inhabitants per km2, Rock Valley was notorious for having the highest population density of Wassies in the metaverse. Remember Wassies? The small, and for some of us edible, creature, found in all colors, with an average lifespan of 14 days? Longer if frozen, but more on that later. Remember? So, Rock Valley had the highest population density of Wassies, with 29,291.4 inhabitants per km2. That's denser than Monaco, in case that says something to you. See, Monaco has an average population of 26,337 inhabitants per km2. Monaco is more expensive, though. In Monaco, a million dollars buy you a 16m2 piece of land. A 16m2 piece of land is what, the size of a self-storage unit? Yeah. In Rock Valley, a million dollars buy you a 235m2 piece of land. That's enough land to build a worthy commercial building on.
    Wassies, energetic and reliable, thrived on what they referred to as Rock Valley's anchored daily predictability and safety. Simple-minded and oblivious to overly restrictive laws and high ﬁnes for disobedience and rebelliousness, they saw themselves as the Valley's colorful essence. But all was about to change, for trouble was heading toward the Valley, and it was heading there fast for northeast of Rock Valley, in a small wet cave located on the west side of a Mountain, having just arisen from months of hibernation, seven evil frogs had begun preparing themselves for their big hunt. The Evil Frogs, as they vainly called themselves, were each named after their favorite color, which just happened to also be their eye color. They were Red, Green, Blue, Magenta, Cyan, Yellow, and Black. Led by Green, they would scavenge the wilderness of the world wide web, chasing down their favorite prey: Wassies. And so, after lavishly bathing and scraping dry mud off their slithery, unformed green bodies, they each packed a small dufﬂe bag and, clustered together, leapt into the sunset.
    </p>
                    </div>
                </div>
            </section>
           

            <div id="set-height" ref={setheight}></div>

            <video id="v0" ref={v0} autobuffer="true" preload="true">
                <source type='video/mp4' src="videos/chapter_1.mp4"></source>
            </video>
            <script src="sticky.js"></script>
				
		</div> 
	);


	
	
};

export default Chapter1;









