import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";


const Shop = (props) => {


    const moveLock = (e) => {
		let ele = document.getElementById(e);
		ele.classList.add("movelock");


	}

	const stopMoveLock = (e) => {
		let ele = document.getElementById(e);
		ele.classList.remove("movelock");
		
	}


    return (
        <div className="App">
        {
            <div>
                
                    <div className="bigcontainer greencontainer webshop">
                        <h1 style={{marginTop:0, marginBottom: "30px", color: "#000"}} >Shop</h1>

                        <h3 style={{marginTop:0, marginBottom: "30px", color: "#000"}} >Coming sooooon</h3>

                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/cap.png" onMouseDown={() => { moveLock('1'); }} onMouseUp={() => { stopMoveLock('1');}} />
                            <img className="lock" src="/merch/lock.png" id="1" />
                        </div>
                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/cap_2.png" onMouseDown={() => { moveLock('2'); }} onMouseUp={() => { stopMoveLock('1');}} />
                            <img className="lock" src="/merch/lock.png" id="2" />
                        </div>
                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/cap_3.png" onMouseDown={() => { moveLock('3'); }} onMouseUp={() => { stopMoveLock('1');}} />
                            <img className="lock" src="/merch/lock.png" id="3" />
                        </div>
                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/coachjacket.png" onMouseDown={() => { moveLock('4'); }} onMouseUp={() => { stopMoveLock('2');}} />
                            <img className="lock" src="/merch/lock.png" id="4"/>
                        </div>
                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/hoodie.png" onMouseDown={() => { moveLock('5'); }} onMouseUp={() => { stopMoveLock('3');}} />
                            <img className="lock" src="/merch/lock.png" id="5"/>
                        </div>
                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/socks.png" onMouseDown={() => { moveLock('6'); }} onMouseUp={() => { stopMoveLock('4');}} />
                            <img className="lock" src="/merch/lock.png" id="6"/>
                        </div>
                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/sweater.png" onMouseDown={() => { moveLock('7'); }} onMouseUp={() => { stopMoveLock('5');}}  />
                            <img className="lock" src="/merch/lock.png" id="7"/>
                        </div>
                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/grinder.png" onMouseDown={() => { moveLock('8'); }} onMouseUp={() => { stopMoveLock('6');}} />
                            <img className="lock" src="/merch/lock.png" id="8"/>
                        </div>
                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/tshirt.png" onMouseDown={() => { moveLock('10'); }} onMouseUp={() => { stopMoveLock('7');}} />
                            <img className="lock" src="/merch/lock.png" id="10"/>
                        </div>
                        <div className="shopitem left p25pad">                            
                            <img className="shoppic" src="/merch/backback.png" onMouseDown={() => { moveLock('11'); }} onMouseUp={() => { stopMoveLock('7');}} />
                            <img className="lock" src="/merch/lock.png" id="11"/>
                        </div>

                        <div className="clearfix"></div>

                        
                    </div>

                <Footer />
            </div>
        }
        </div>
    );
};

export default Shop;
