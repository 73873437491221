import {useEffect, useState, useRef} from "react";
import {useInView} from 'react-intersection-observer';
import { Link } from "react-router-dom";

import Header from "./Header";

import {
    totalSupply,
	tryToMint,
	tryToMintWL,
	mintPhase
} from "./utils/interact.js";

import { whiteListAddresses } from "./utils/whitelistaddresses.js";

const StoryWhitelist = (props) => {

	//const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	const {ref, inView, entry} = useInView({

		/* Optional options */

		threshold: 0,

	});

	const [lockedTitle, setLockedTitle] = useState("centertext title");
	const [isShown, setIsShown] = useState(false);
	const [frogstop, setFrogsTop] = useState(-550);

	const [loading,setLoading] = useState(true);
	const [loadingO,setLoadingO] = useState(0);

	const [subscribe, setSubscribe] = useState(false);
	const [moreInfo, setMoreInfo] = useState(false);

	const [mintinginProgress, setMintinginProgress] = useState(false);

	const [showSubBox, setShowSubBox] = useState("");

	const [supplyNotReached, setSupplyNotReached] = useState(false);
	const [mintingPhase, setMintingPhase] = useState(0);
	const [supply, setSupply] = useState(0);

	let global = useRef();
	let frame = useRef();
	let frogs = useRef();
	let mountains = useRef();
	let frenRef = useRef();

	let wrapsubbox = useRef();
	let mintgoback = useRef();
	let mintwrapper = useRef();
	let mintingwrapper = useRef();

	const maxMint = 333;

	useEffect(() => {

		//frame = document.getElementById("wrapper");
		//moreInfoFrame = document.getElementById("moreinfo");
		//frogs = document.getElementById("f");
		//mountains = document.getElementById("mountains");

		setMoreInfo(false);
		setSubscribe(false);

		const observer = new IntersectionObserver(entries => {
			// Loop through the entries
			entries.forEach(entry => {
			  // If the entry is intersecting the viewport
			  if (entry.isIntersecting) {
				// Replace the src attribute with the data-src attribute
				entry.target.src = entry.target.dataset.src;
				// Unobserve the image
				observer.unobserve(entry.target);
			  }
			});
		  });

		  document.querySelectorAll('img[data-src]').forEach(img => {
			observer.observe(img);
		});

		
		window.setTimeout(hideLoader,400);

		const fetchData = async () => {

			let supply = await totalSupply();

			setSupply(supply);

			if(supply <= 3333)
			{
				setSupplyNotReached(true);
			}
				
			else{
				setSupplyNotReached(false);
			}

			let phase = await mintPhase();
			setMintingPhase(phase);

		}

		fetchData();

		setInterval(getSupply, 20000);

		return () => {}
	}, []);



	const drawbackFren = () => {

		frenRef.current.classList.add("hide");
	}

	const comebackFren = () => {

		frenRef.current.classList.remove("hide");
	}

	const getSupply = async () => {
		
		let supply = await totalSupply();
		setSupply(supply);
		
	}



	const hideLoader = () => {


		window.setTimeout(() => {setLoadingO(100);setLoading(false);},0);
		window.setTimeout(() => {setFrogsTop(0);},500);

		window.setTimeout(() => {wrapsubbox.current?.classList.add("visible");
	
		window.addEventListener("mousemove", changePerspective);},2000);

	}


	/*const startScroll = () => {

		let currentFrame = frame?.current;
		
		let c = 0;
		let interval = window.setInterval(() => {

			c++;

			/*if (currentFrame?.scrollTop > 350) {
				setLockedTitle("centertext title titlelocked");
			} else {
				setLockedTitle("centertext title");
			} */

			/*

			if(c <= 84)
			{
				currentFrame.scroll({ top: parseInt(currentFrame.scrollTop) + c });
				
			}	
			
			if(c == 1)
			{
				setFrogsTop(0);
				
			}

			if(c == 2)
			{				
				setShowSubBox("fadeIn");
				
			}	

			if(c == 3)
			{				
				window.addEventListener("mousemove", changePerspective);
				clearInterval(interval);
			}

			


			/*

			if(c == 86)
			{
				currentFrame.onscroll = function () {
					currentFrame.scrollTop = 3600;
				};	
			}

			

			if(c == 110)
			{				
				window.addEventListener("mousemove", changePerspective);
				clearInterval(interval);	
			}

		},1000)
		
	}*/



	const changePerspective = (e) => {

		let w = window.innerWidth;
		let h = window.innerHeight;

		let rot = e.y / 6;

		rot /= 10;

		if(frogs !== null && frogs.current !== null)
		{
			frogs.current.style.top = rot+"px";

			rot /= 6;
			rot += 6.5;
	
			frame.current.style.perspective = rot+"px";		
		}

	}	

	const moveLock = (e) => {
		let ele = document.getElementById(e);
		ele.classList.add("movelock");


	}

	const stopMoveLock = (e) => {
		let ele = document.getElementById(e);
		ele.classList.remove("movelock");
		
	}

	const openMint = () => {

		wrapsubbox.current.classList.add("hide");
		mintgoback.current.classList.remove("hide");
		mintwrapper.current.classList.remove("hide");


		frame.current.classList.add("zoomed");
		frogs.current.classList.add("zoomed");

		if(mintinginProgress)
		{
			mintingwrapper.current.classList.remove("hide");
		}
		else
		{
			mintingwrapper.current.classList.add("hide");
		}

		getSupply();

	}

	const closeMint = () => {

		wrapsubbox.current.classList.remove("hide");
		mintgoback.current.classList.add("hide");
		mintwrapper.current.classList.add("hide");


		frame.current.classList.remove("zoomed");
		frogs.current.classList.remove("zoomed");

		mintingwrapper.current.classList.add("hide");

		getSupply();

	}

	const [count, setCount] = useState(1);
  
	const increment = () => {
		if (count < 333) {
		setCount(count + 1);
		}
	};
	
	const decrement = () => {
		if (count > 1) {
		setCount(count - 1);
		}
	};

	return (
		<div className="global" >
			
			<div className="containerstory"> 

			
			{(loading) &&
			(
				<img src="loading.png" className="loading"></img>
								
			)}

				<div className="container2" style={{ opacity: loadingO }} ref={global}>

							
							<div className="copyright">
								©2022 Fairybaby420<br />
								<Link to="/terms">
								Terms & Conditions
								</Link><br />
								<a href="https://etherscan.io/address/0x213E46C9273bE8595CfC7a266F0978782C2e6a24" target="_blank">
								Contract on Etherscan
								</a>
							</div>





							<div className="mintgoback hide" ref={mintgoback}>
									<img data-src="back.png" src="" onClick={() => { closeMint();}} />	
							</div>


							<div className="mintwrapper hide" ref={mintwrapper}>
								
								{ /*<div ref={hasWhitelist}>

								</div>

								<div ref={hasNoWhitelist}>

								</div> */}
								<div>
									
									

									{props.user != null && props.user.walletAddress != null && props.user.walletAddress != "" ? (

										<div>

										{
										
										!mintinginProgress && (

											<div>

												<h2>Gmairy</h2>

												{		
												
													mintingPhase == 0 && (
														<div>

															<h5 className="">We haven't even started yet.<br />Please check back later.</h5>

																<a className="smallfont" href="https://discord.gg/dYpKAHUbcE" target="_blank">&gt; Join our Discord and sit by the bonfire &lt;</a><br />
																<a className="smallfont" href="https://twitter.com/FairyFrenz" target="_blank">&gt; Follow FairyFrenz on Twitter &lt;</a>
															<br /><br />

														</div>
													)
												}

												{
													//Whitelist Minting
													mintingPhase == 1 && (

														<div>

														{
															props.user.isWhitelisted ? ( 

															<div>
																
																<div className="smallfont">You are whitelisted and can press this pretty button to mint your FairyFrenz.</div>

																{ supplyNotReached ? (
																	<div>
																		<div className="smallfont">Your price: {(count * 0.0333).toFixed(4)} - Total minted: {supply} </div>
																		<div className="number-input">
																			<button onClick={decrement}>-</button>
																			<input type="number" value={count} min="1" max="333" readOnly />
																			<button onClick={increment}>+</button>
																		</div>

																		<div className="wrapbigbuttonmint"  onClick={() => { tryToMintWL(whiteListAddresses, props.user.walletAddress, count, mintwrapper, mintingwrapper, setMintinginProgress );}}>
																			<img src="mintbuttonani.gif" className="mintbuttonbigfren" alt="MINT FAIRYFREN" />
																			<img src="mintbutton.png" className="mintbuttonbig" alt="MINT FAIRYFREN" />

																		</div>	
																	</div>												
																) : (
																	<div >

																		<h1>SOLD OUT</h1>

																	</div>
																)}
																
															</div>
															

															) : (

															<div>
																<br /><br />
																You are not whitelisted! <br />
																HFSP.

																<br /><br />
																<a className="smallfont" href="https://discord.gg/dYpKAHUbcE" target="_blank">&gt; Join our Discord and sit by the bonfire &lt;</a><br />
																<a className="smallfont" href="https://twitter.com/FairyFrenz" target="_blank">&gt; Follow FairyFrenz on Twitter &lt;</a>
																<br /><br />
																
															</div>	

															)
														}

														</div>
													)
												}

												{

													//Public Mint	
													mintingPhase == 2 && (
														<div>
																
																<div className="smallfont">Public mint is live! You can press this pretty button to mint your FairyFrenz.</div>

																{ supplyNotReached ? (
																	<div>
																		<div className="smallfont">Your price: {(count * 0.0666).toFixed(4)} - Total minted: {supply} </div>
																		<div className="number-input">
																			<button onClick={decrement}>-</button>
																			<input type="number" value={count} min="1" max="333" readOnly />
																			<button onClick={increment}>+</button>
																		</div>

																		<div className="wrapbigbuttonmint"  onClick={() => { tryToMint(whiteListAddresses, props.user.walletAddress, count,  mintwrapper, mintingwrapper, setMintinginProgress);}}>
																			<img src="/animation.gif" className="mintbuttonbigfren" alt="MINT FAIRYFREN" />
																			<img src="mintbutton.png" className="mintbuttonbig" alt="MINT FAIRYFREN" />

																		</div>	
																	</div>												
																) : (
																	<div >

																		<h1>SOLD OUT</h1>

																	</div>
																)}
																
															</div>
													)
												}

												{

													//Mint is Over
													mintingPhase == 3 && (
														<div>

															<h5 className="">The Mint is Over.</h5>

																<a className="smallfont" href="https://opensea.com" target="_blank">&gt; See FairyFrenz on OpenSea &lt;</a><br />

																<a className="smallfont" href="https://twitter.com/FairyFrenz" target="_blank">&gt; Follow FairyFrenz on Twitter &lt;</a>
																<br /><br />

														</div>
													)
												}

											</div>
											)}
										</div>

									) : (

										<div className="mintwrapper">
											
											<br /><br />
											<h2>Error</h2>
											<h6>Please connect ze wallet first.	</h6>	
											<br /><br />							

										</div>


									)}

								</div>
								
							</div>	

							<div className="mintwrapper hide" ref={mintingwrapper}>


							</div>			

							{ /* <h2>{`Header inside viewport ${inView}.`}</h2> */}


							<div className="wrapper" id="wrapper" ref={frame} style={{
								maxHeight: "100vh",
								backgroundPosition: "0 -150px",
								backgroundImage: "url('01-Background.png')"
							}}

							>  

								<header >								

									<img data-src="02-Clouds.png" src="" className="l l1"/>
									<img data-src="03-Digital-Mountains.png" src="" className="l l2"/>
									<img data-src="04-Blue-Mountains.png" src="" className="l l3"/>

									<img data-src="05-Forest.png" src="" className="l l4"/>
									<img data-src="05-Fog.png" src="" className="l l42 lfog"/>
									<img data-src="06-Rocks.png" src="" className="l l5" id="mountains" ref={mountains}/>

								</header>

								
							</div>

							<div className="f" id="f" ref={frogs} style={{bottom: frogstop}}>
									<img data-src="07-Frog-Blue.png" src="" className="f1 l lf1 " ref={ref}/>
									<img data-src="08-Frog-Yellow.png" src="" className="l lf1 "/>
									<img data-src="08-Frog-Yellow_Closed-Eye.png" src="" className="l f1 lf1 blinkfrog8"/>
									<img data-src="09-Frog-Magenta.png" src="" className="l lf1 f3 "/>
									<img data-src="10-Frog-Black.png" src="" className="l lf1 "/>
									<img data-src="11-Frog-Cyan.png" src="" className="l lf1 f2 "/>
									<img data-src="12-Frog-Red.png" src="g" className="l f4 lf1 "/>
									<img data-src="13-Frog-Green.png" src="" className="l lf1 "/>
									<img data-src="13-Frog-Green_Closed-Eye.png" src="" className="l lf1 blinkfrog13"/>
									<img data-src="14-Shadow.png" src="" className="l6"/>

							</div>						


			</div>
			

			</div>	
		</div> 
	);
	
	
};

export default StoryWhitelist;









