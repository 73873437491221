import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";







const About = (props) => {


    


    return (
        <div className="App">
        {
            <div>
                
                    <div className="bigcontainer greencontainer">
                        
                        <div className="left p45 wrapframe">
                            
                            <img src={"/animation.gif"} className="framebehind" />

                        </div>

                        <div className="right p50">

                            <h1 style={{marginTop:0, marginBottom: "30px", color: "#000"}} >About</h1>
                            <h4>Before the beginning of everything, there was a beginning...</h4>
                            <p>FairyFrenz by Fairybaby420 is in the midst of releasing the first original crypto fairy tale in the universe of Web3, embedded in a collection of 3333 Badazz FairyFrenz PFPs, stored on the Ethereum blockchain. The hodling of such a PFP makes you A) a real fucking FairyFren B) an IP owner of some of the wittiest degen illerature, and C) an overall AAA FairyFren.</p>
                            <p><strong>Join now, regretti later!!</strong></p><br /><br />

                            <h2>Let's fucking go! </h2>
                            <h3>3333 Badazz FaiyFrenz PFPs!</h3>
                            <p>If you're into numbers you'll know that the number 3333 appears in your life at a particular time and is never coincidental. And if you aren't? We'll explain: 3333 combines energies of 3, 33, and 333 and points to exactly what you need in life: Badazz FairyFrenz PFPs!</p>
                            <p>With the following characteristics, the 3333 Badazz FairyFrenz PFPs are the FairyFrenz' top-tier NFTs:</p>
                            <ul>
                                <li>Ability to unlock various utilities throughout the FairyFrenz Journey</li>
                                <li>Unique in design, featuring hand-drawn traits created by Fairybaby420</li>
                                <li>Fairybaby420 will always put FairyFrenz first in anything he touches in ze future of finance</li>
                                <li>They live as ERC-721 tokens on Ethereum and are hosted on IPFS</li>
                                
                            </ul>
                            <br /><br />
                            <h3>Dates and Prices</h3>
                            <h4>Whitelist on June 6th 22H00 CET</h4>
                            <li>The price of a single PFP will be 0.0333 Efiriums</li><br />
                            <h4>Public Launch on June 9th 18H00 CET</h4>
                            <li>The price of a single PFP will be 0.0666 Efiriums</li>

                            <br /><br />
                            <h2>Vision</h2>
                            <p>FairyFrenz is created by shitposter/artist Fairybaby420 and frenz. After 3 years of observing the fast-paced and rapidly expanding NFT space, Fairybaby420 and frenz, brought together their skills and morphed them into the ultimate full-blown meme-fused degens WAGMI experience that is here to stay! 100% inspired by the space and yet 100% new to it: Fairy fucking Frenz!!</p>
                            <p>With FairyFrenz, we are aiming to use some of Web3's most disruptive tools to break down outdated power structures by deleting the middlemen, getting closer to the consumer, and handing the power back to the creatoooooooors!</p>
                            <p>At the core of our multi-layered product is our first original crypto fairy tale, written, illustrated, and coded by frenz and Fairybaby420. After its release, FairyFrenz as a community can rally for what we believe in:</p>
                            <ul>
                                <li>Let's create self-sufficiency! Let's!</li>
                                <li>Let's invite artists, writers, and our favorite degens to collaborate! Let's!</li>
                                <li>Let's find out who else in the community would like to become a creator! Let's!</li>
                            </ul>
                            <p>The possibilities are endless so let's fucking have fun with this! Let's! Let's! Let's! Let's!</p>

                            <br /><br />

                            <h2>FAQ</h2>
                            <h3>Why buy a Badazz FairyFrenz PFP?</h3>
                            <p>Well, unlike other projects, your NFT is not just a token attached to a lousy jpeg with false promises lol. With a FairyFrenz PFP, Web3's first original crypto fairy tale will be yours, IP rights and AAA FairyFrenz status included!</p>
                            <h3>What else can I do with the Badazz FairyFrenz PFP?</h3>
                            <p>Plz jump to the <strong>JOURNEY</strong> section.</p>
                            <h3>Will I also own the IP rights to the illustrations of the first original crypto fairy tale?</h3>
                            <p>No, don't get too greedy. Of course, they belong to Fairybaby420's core team.</p>
                            <h3>As a Badazz FairyFrenz PFP hodler, can I write a story based on one or more characters of the crypto fairy tale, illustrate it, and sell it?</h3>
                            <p>YES</p>

                        </div>

                        <div className="clearfix" />

                    </div>

                <Footer />
            </div>
        }
        </div>
    );
};

export default About;
