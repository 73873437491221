import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";


const Terms = (props) => {
    return (
        <div className="App">
        {
            <div>
                
                    <div className="bigcontainer greencontainer">
                        <h3>Terms and Conditions</h3>

                        <div className="">
                        <p>FairyFrenz PFPs is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing participants to exchange digital collectibles. Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the FairyFrenz PFPs smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.</p>
                        
                        <p>This website and its connected services are provided "as is" and "as available" without warranty of any kind. By using this website you are accepting sole responsibility for any and all transactions involving FairyFrenz PFPs digital collectibles.</p>
                        
                        <br /><br />
                        <h4>IP Rights</h4>

                        <p>i. You Own the NFT. Each FairyFrenz is an NFT on the Ethereum blockchain. When you purchase an NFT, you own the underlying FairyFrenz, the Art, the first original crypto fairy tale, completely. Ownership of the NFT is mediated entirely by the smart contract and the Ethereum Network: at no point may we seize, freeze, or otherwise modify the ownership of any FairyFrenz.</p>

                        <p>ii. Personal Use. Subject to your continued compliance with these Terms, Fairybaby420 and FairyFrenz grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your FairyFrenz / NFT, provided that the marketplace cryptographically verifies each FairyFrenz owner's rights to display the Art for their FairyFrenz to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your FairyFrenz, provided that the website/application cryptographically verifies each FairyFrenz owner's rights to display the Art for their FairyFrenz to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the FairyFrenz leaves the website/application.</p>

                        <p>iii. Commercial Use. Subject to your continued compliance with these Terms, Fairybaby420 and FairyFrenz grants you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art ("Commercial Use"). Examples of such Commercial Use would e.g. be the use of the Art to produce and sell merchandise products (T-Shirts, etc.) displaying copies of the Art. For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and sale of FairyFrenz PFPs generally, provided that the marketplace cryptographically verifies each FairyFrenz owner's rights to display the Art for their FairyFrenz to ensure that only the actual owner can display the Art; (ii) owning or operating a third party website or application that permits the inclusion, involvement, or participation of FairyFrenz PFPs generally, provided that the third party website or application cryptographically verifies each FairyFrenz owner's rights to display the Art for their FairyFrenz to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased FairyFrenz leaves the website/application; or (iii) earning revenue from any of the foregoing.</p>
                        </div>
                    </div>

                <Footer />
            </div>
        }
        </div>
    );
};

export default Terms;
